import { Controller } from '../Controller';
import { IMembershipQuestionsApi } from '@wix/social-groups-api/dist/src/services/membership-quetions';
import { IMembershipQuestions } from '../../contexts/MembershipQuestions/IMembershipQuestions';
import {
  CreateOrReplaceAllMembershipQuestionsRequest,
  ListMembershipQuestionsRequest,
  MembershipQuestion,
} from '@wix/ambassador-social-groups-v2-membership-question/types';
import { PubSubEventTypes } from 'Group/controllers/pubSub/PubSubEventTypes';

export class MembershipQuestions
  extends Controller<IMembershipQuestions>
  implements IMembershipQuestionsApi {
  pageReady(): Promise<any> {
    this.setProps({
      membershipQuestionsActions: {
        listMembershipQuestions: this.listMembershipQuestions,
        saveMembershipQuestions: this.saveMembershipQuestions,
      },
    });
    return Promise.resolve(undefined);
  }

  listMembershipQuestions = async (
    payload: ListMembershipQuestionsRequest,
  ): Promise<MembershipQuestion[]> => {
    let questions: MembershipQuestion[] = [];

    try {
      questions = await this._api
        .getMembershipQuestionsApi()
        .listMembershipQuestions(payload);
    } catch (e) {
      console.error(e);
    }

    this.setProps({
      questions,
    });
    return questions;
  };

  saveMembershipQuestions = async (
    payload: CreateOrReplaceAllMembershipQuestionsRequest,
  ): Promise<void> => {
    try {
      await this._api
        .getMembershipQuestionsApi()
        .saveMembershipQuestions(payload);
    } catch (e) {
      console.error(e);
    }
    this.publish(PubSubEventTypes.SAVE_MEMBERSHIP_QUESTIONS, {});
  };
}
