import * as IReactionsTypes from '@wix/ambassador-reactions-v1-reaction/types';

import { observable } from 'mobx';
import { date, list, object, primitive, raw, serializable } from 'serializr';

import * as FeedTypes from 'FeedWidget/api/types';

import {
  IReaction,
  IReactions,
  IUserReaction,
  IUsersReacted,
  IUsersReactions,
} from '../../../types/IFeedItem';

class Reaction implements IReaction {
  @serializable reactionCode: string;

  constructor(reaction: IReactionsTypes.Reaction) {
    this.reactionCode = reaction.reactionCode!;
  }
}

export class UserReaction implements IUserReaction {
  @serializable(raw()) user: FeedTypes.IGroupMember;

  @serializable(object(Reaction)) reaction: Reaction;

  @serializable(date()) reactionTime: Date;

  constructor(userReaction: FeedTypes.IUserReaction) {
    this.user = userReaction.user;
    this.reactionTime = new Date(userReaction.reactionTime!);
    this.reaction = new Reaction(userReaction.reaction!);
  }

  equals(userReaction: FeedTypes.IUserReaction) {
    const isSameUser =
      this.user.siteMemberId === userReaction.user.siteMemberId;
    const isSameReaction =
      this.reaction.reactionCode === userReaction.reaction!.reactionCode;

    return isSameUser && isSameReaction;
  }
}

class UsersReactions implements IUsersReactions {
  @serializable
  @observable
  total: number;

  @serializable(list(object(UserReaction)))
  @observable.shallow
  reactions: UserReaction[];

  constructor(usersReactions: FeedTypes.IUsersReactions) {
    this.total = usersReactions.total!;
    this.reactions = usersReactions.reactions!.map(
      (userReaction) => new UserReaction(userReaction),
    );
  }

  has(userReaction: FeedTypes.IUserReaction) {
    return !!this.reactions.find((reaction) => reaction.equals(userReaction));
  }

  add(userReaction: FeedTypes.IUserReaction) {
    this.total = this.reactions.push(new UserReaction(userReaction));
    return this.total;
  }

  remove(userReaction: FeedTypes.IUserReaction) {
    try {
      const reactions = this.reactions.filter(
        (r) =>
          !(
            r.user.siteMemberId === userReaction.user.siteMemberId &&
            r.reaction.reactionCode === userReaction.reaction!.reactionCode
          ),
      );
      this.reactions = reactions;
      this.total = this.reactions.length;
    } catch (e) {
      console.log('UsersReactions.remove error');
    }

    return this.total;
  }

  getUsers() {
    return this.reactions.map((r) => r.user);
  }
}

class UsersReacted implements IUsersReacted {
  @serializable
  @observable
  total: number;

  @serializable(list(raw()))
  @observable.shallow
  users: FeedTypes.IGroupMember[];

  constructor(usersReacted: FeedTypes.IUsers) {
    this.total = usersReacted.total!;
    this.users = usersReacted.users!;
  }
}

export class Reactions implements IReactions {
  @serializable total: number;

  @serializable(object(UsersReactions))
  @observable
  usersReactions: UsersReactions;

  @serializable(object(UsersReacted)) @observable usersReacted: UsersReacted;

  constructor(
    reactionsSummary: FeedTypes.IReactionsSummary = {
      total: 0,
      usersReactions: {
        total: 0,
        reactions: [],
      },
      usersReacted: {
        total: 0,
        users: [],
      },
    },
  ) {
    this.total = reactionsSummary.total || 0;
    this.usersReactions = new UsersReactions(reactionsSummary.usersReactions!);
    this.usersReacted = new UsersReacted(reactionsSummary.usersReacted!);
  }

  add(userReaction: FeedTypes.IUserReaction) {
    this.usersReactions.add(userReaction);
    this.setUsersReactedFromReaction();
    return this.total;
  }

  has(userReaction: FeedTypes.IUserReaction) {
    return this.usersReactions.has(userReaction);
  }

  private setUsersReactedFromReaction() {
    const users = Array.from(new Set(this.usersReactions.getUsers()));
    this.usersReacted = new UsersReacted({ total: users.length, users });
    this.setTotalReactions();
  }

  remove(userReaction: FeedTypes.IUserReaction) {
    this.usersReactions.remove(userReaction);
    this.setUsersReactedFromReaction();
    return this.total;
  }

  private setTotalReactions() {
    this.total = this.usersReacted.total;
  }

  getTotalReactions() {
    return this.usersReactions.total;
  }
}
